/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
/**
* @Version: 1.0
 * @Last Modified by: 任笠
 * @Last Modified time: 2021-08-03 14:12:26
**/
/*大标题*/
.font-h1 {
  color: #303133;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
/*标题*/
.font-h2 {
  color: #303133;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
/*副标题*/
.font-h3 {
  color: #606266;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
/*正文*/
.font-p-1 {
  color: #303133;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
/*辅助文本*/
.font-p-2 {
  color: #9a9a9a;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
/*边框*/
.border {
  border: 1px solid #dedede;
}
.primary-color {
  /*字体 主体色*/
}
[data-theme='theme'] .primary-color {
  color: #1890ff;
}
[data-theme='theme1'] .primary-color {
  color: #3b97fe;
}
[data-theme='theme2'] .primary-color {
  color: #1173ce;
}
[data-theme='theme3'] .primary-color {
  color: #212122;
}
[data-theme='theme4'] .primary-color {
  color: #009b94;
}
[data-theme='theme5'] .primary-color {
  color: #2f54eb;
}
.primary-border-color {
  /*border 主体色*/
}
[data-theme='theme'] .primary-border-color {
  border-color: #1890ff;
}
[data-theme='theme1'] .primary-border-color {
  border-color: #3b97fe;
}
[data-theme='theme2'] .primary-border-color {
  border-color: #1173ce;
}
[data-theme='theme3'] .primary-border-color {
  border-color: #212122;
}
[data-theme='theme4'] .primary-border-color {
  border-color: #009b94;
}
[data-theme='theme5'] .primary-border-color {
  border-color: #2f54eb;
}
.default-color {
  color: #1890ff;
}
.warning-color {
  color: #f26f3e;
}
.success-color {
  color: #32b082;
}
.danger-color {
  color: #e83426;
}
.info-color {
  color: #909399;
}
.default-bg-color {
  background: #1890ff;
}
.warning-bg-color {
  background: #f26f3e;
}
.success-bg-color {
  background: #32b082;
}
.danger-bg-color {
  background: #e83426;
}
.ant-upload-list-item-info {
  line-height: 1.4;
}
.p-b-52 {
  padding-bottom: 52px;
}
.bordered {
  border: 1px solid #ddd;
}
.bg-white {
  background: #fff;
}
[data-theme^='theme'] .ant-modal-mask {
  background: rgba(0, 0, 0, 0.3);
}
.ant-modal-confirm .ant-modal-confirm-title {
  font-size: 18px;
  font-weight: 600;
}
.ant-modal-confirm .ant-modal-confirm-content {
  min-height: 30px;
}
ul.info-list li.info-item:before,
ul.info-list li.info-item:after {
  display: table;
  content: ' ';
}
ul.info-list li.info-item:after {
  clear: both;
}
ul.info-list li.info-item {
  margin-bottom: 6px;
}
ul.info-list li.info-item div.info-item-label {
  float: left;
  text-align: left;
  height: 34px;
  line-height: 34px;
  width: 120px;
  color: #9a9a9a;
  overflow: hidden;
  white-space: nowrap;
}
ul.info-list li.info-item div.info-item-control {
  margin-left: 125px;
}
ul.info-list li.info-item div.info-item-control .ant-calendar-picker-input {
  padding: 0;
}
ul.info-list li.info-item div.info-item-control .ant-calendar-range-picker-input {
  width: 129px;
  text-align: left;
}
ul.info-list li.info-item div.info-item-control .ant-input {
  border: none;
}
.btn {
  display: inline-block;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #383838;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.btn.option-gray {
  background: rgba(156, 156, 156, 0.1);
  color: #303030;
}
.btn.option-green {
  background: rgba(114, 213, 154, 0.1);
  color: #72d59a;
}
.btn.option-yellow {
  background: rgba(255, 168, 17, 0.1);
  color: #ffa811;
}
.btn.option-purple {
  background: rgba(124, 103, 246, 0.1);
  color: #7c67f6;
}
.btn.option-red {
  background: rgba(241, 47, 43, 0.1);
  color: #f12f2b;
}
.btn.btn0 {
  color: #303133;
  background: rgba(156, 156, 156, 0.1);
}
.btn.btn1 {
  color: #ffa811;
  background: rgba(255, 168, 17, 0.1);
}
.btn.btn2 {
  color: #f12f2b;
  background: rgba(241, 47, 43, 0.1);
}
.btn.btn3 {
  background: rgba(156, 156, 156, 0.1);
}
.page-box {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.page-box .page-title {
  margin-bottom: 0px;
}
.page-box .page-content {
  padding: 20px;
  flex: 1;
}
.page-box .page-content .table-box {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page-box .page-content .table-box .table-table {
  flex: 1;
}
.page-box .page-content .table-box-special {
  height: 100%;
  overflow: hidden;
}
.page-box .page-content .table-box-special .table-table {
  height: 100%;
  overflow-y: auto;
}
.page-box .page-content .page-content-left,
.page-box .page-content .page-content-right {
  height: 100%;
  overflow: hidden;
}
.page-box .page-content .page-content-left {
  margin-right: 20px;
}
.pannel {
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.pannel .pannel-title {
  padding: 20px;
  border-bottom: 1px solid #dddddd;
}
.pannel .pannel-title .ant-form-vertical .ant-form-item {
  padding-bottom: 0px;
  margin: 0;
}
.pannel .pannel-title .search-heigh {
  margin-top: 20px;
}
.pannel .pannel-title .ant-calendar-picker {
  width: 100%;
}
.pannel .pannel-title.top {
  border-bottom: none;
  border-top: 1px solid #dddddd;
  padding: 10px 20px;
  text-align: right;
}
.pannel .pannel-content {
  padding: 20px;
  flex: 1;
  overflow: hidden;
}
.add-icon {
  /*背景色*/
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
[data-theme='theme'] .add-icon {
  background-color: rgba(24, 144, 255, 0.1);
}
[data-theme='theme1'] .add-icon {
  background-color: rgba(59, 151, 254, 0.1);
}
[data-theme='theme2'] .add-icon {
  background-color: rgba(17, 115, 206, 0.1);
}
[data-theme='theme3'] .add-icon {
  background-color: rgba(48, 87, 157, 0.1);
}
[data-theme='theme4'] .add-icon {
  background-color: rgba(0, 155, 148, 0.1);
}
[data-theme='theme5'] .add-icon {
  background-color: #dde3ff;
}
.add-icon:hover {
  /*背景色*/
}
[data-theme='theme'] .add-icon:hover {
  background-color: #1890ff;
}
[data-theme='theme1'] .add-icon:hover {
  background-color: #3b97fe;
}
[data-theme='theme2'] .add-icon:hover {
  background-color: #1173ce;
}
[data-theme='theme3'] .add-icon:hover {
  background-color: #30579d;
}
[data-theme='theme4'] .add-icon:hover {
  background-color: #009b94;
}
[data-theme='theme5'] .add-icon:hover {
  background-color: #2f54eb;
}
.add-icon .plus {
  /*字体 主体色*/
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
}
[data-theme='theme'] .add-icon .plus {
  color: #1890ff;
}
[data-theme='theme1'] .add-icon .plus {
  color: #3b97fe;
}
[data-theme='theme2'] .add-icon .plus {
  color: #1173ce;
}
[data-theme='theme3'] .add-icon .plus {
  color: #212122;
}
[data-theme='theme4'] .add-icon .plus {
  color: #009b94;
}
[data-theme='theme5'] .add-icon .plus {
  color: #2f54eb;
}
.add-icon:hover .plus {
  color: #fff;
}
.icon-selected {
  /*字体 主体色*/
}
[data-theme='theme'] .icon-selected {
  color: #1890ff;
}
[data-theme='theme1'] .icon-selected {
  color: #3b97fe;
}
[data-theme='theme2'] .icon-selected {
  color: #1173ce;
}
[data-theme='theme3'] .icon-selected {
  color: #212122;
}
[data-theme='theme4'] .icon-selected {
  color: #009b94;
}
[data-theme='theme5'] .icon-selected {
  color: #2f54eb;
}
.m-box .m-title:before,
.m-box .m-title:after {
  display: table;
  content: ' ';
}
.m-box .m-title:after {
  clear: both;
}
.m-box .m-title {
  padding: 10px 0 10px 0;
}
.m-box .m-title .m-text {
  float: left;
  padding-left: 8px;
  font-size: 16px;
  border-left: 3px solid;
  /*border 主体色*/
  /*字体 主体色*/
}
[data-theme='theme'] .m-box .m-title .m-text {
  border-color: #1890ff;
}
[data-theme='theme1'] .m-box .m-title .m-text {
  border-color: #3b97fe;
}
[data-theme='theme2'] .m-box .m-title .m-text {
  border-color: #1173ce;
}
[data-theme='theme3'] .m-box .m-title .m-text {
  border-color: #212122;
}
[data-theme='theme4'] .m-box .m-title .m-text {
  border-color: #009b94;
}
[data-theme='theme5'] .m-box .m-title .m-text {
  border-color: #2f54eb;
}
[data-theme='theme'] .m-box .m-title .m-text {
  color: #1890ff;
}
[data-theme='theme1'] .m-box .m-title .m-text {
  color: #3b97fe;
}
[data-theme='theme2'] .m-box .m-title .m-text {
  color: #1173ce;
}
[data-theme='theme3'] .m-box .m-title .m-text {
  color: #212122;
}
[data-theme='theme4'] .m-box .m-title .m-text {
  color: #009b94;
}
[data-theme='theme5'] .m-box .m-title .m-text {
  color: #2f54eb;
}
.m-box .m-content {
  margin: 10px 0 0 0;
}
.m-box .m-content .m-item {
  margin-bottom: 10px;
}
.m-box .m-content .m-item .m-label {
  color: #888888;
}
.m-box .m-content .m-item .m-value {
  display: inline-block;
}
.m-box .m-content .m-item .m-value.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  /*字体 主体色*/
}
[data-theme='theme'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
[data-theme='theme'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: #1890ff;
}
[data-theme='theme1'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
[data-theme='theme1'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: #3b97fe;
}
[data-theme='theme2'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
[data-theme='theme2'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: #1173ce;
}
[data-theme='theme3'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
[data-theme='theme3'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: #212122;
}
[data-theme='theme4'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
[data-theme='theme4'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: #009b94;
}
[data-theme='theme5'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
[data-theme='theme5'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: #2f54eb;
}
.steps-box {
  padding: 10px 25px;
  padding-bottom: 0;
}
.steps-box .steps-content .steps-content-item {
  padding: 25px 0;
}
.steps-box .steps-content .steps-content-item .modal-body {
  min-height: 300px;
}
/*icon-btn*/
.icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding: 5px;
}
.icon-btn:hover {
  /*背景色*/
}
[data-theme='theme'] .icon-btn:hover {
  background-color: rgba(24, 144, 255, 0.1);
}
[data-theme='theme1'] .icon-btn:hover {
  background-color: rgba(59, 151, 254, 0.1);
}
[data-theme='theme2'] .icon-btn:hover {
  background-color: rgba(17, 115, 206, 0.1);
}
[data-theme='theme3'] .icon-btn:hover {
  background-color: rgba(48, 87, 157, 0.1);
}
[data-theme='theme4'] .icon-btn:hover {
  background-color: rgba(0, 155, 148, 0.1);
}
[data-theme='theme5'] .icon-btn:hover {
  background-color: #dde3ff;
}
.icon-btn.disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.rowActive {
  /*背景色*/
}
[data-theme='theme'] .rowActive {
  background-color: rgba(24, 144, 255, 0.1);
}
[data-theme='theme1'] .rowActive {
  background-color: rgba(59, 151, 254, 0.1);
}
[data-theme='theme2'] .rowActive {
  background-color: rgba(17, 115, 206, 0.1);
}
[data-theme='theme3'] .rowActive {
  background-color: rgba(48, 87, 157, 0.1);
}
[data-theme='theme4'] .rowActive {
  background-color: rgba(0, 155, 148, 0.1);
}
[data-theme='theme5'] .rowActive {
  background-color: #dde3ff;
}
.table-search .ant-input-affix-wrapper {
  width: 400px;
}
.ant-table-content {
  border: 0;
  border-radius: 2px;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #ebeef5;
}
.ant-table-fixed-left {
  border-left: 0 !important;
  border-top: 0 !important;
}
.ant-table-fixed-right {
  border-right: 0 !important;
  border-top: 0 !important;
}
.ant-table-scroll .ant-table-thead tr:first-child th:nth-child(1) {
  padding: 13px 8px 13px 24px !important;
}
.ant-table-scroll .ant-table-tbody tr td:nth-child(1) {
  padding: 13px 8px 13px 24px !important;
}
.table-area-tab {
  min-height: calc(100vh - 196px);
  overflow-y: auto;
  position: relative;
}
.table-divider {
  margin-left: -20px;
  width: calc(100% + 40px);
}
.ant-btn.ant-btn-link {
  /*字体 主体色*/
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
[data-theme='theme'] .ant-btn.ant-btn-link {
  color: #1890ff;
}
[data-theme='theme1'] .ant-btn.ant-btn-link {
  color: #3b97fe;
}
[data-theme='theme2'] .ant-btn.ant-btn-link {
  color: #1173ce;
}
[data-theme='theme3'] .ant-btn.ant-btn-link {
  color: #212122;
}
[data-theme='theme4'] .ant-btn.ant-btn-link {
  color: #009b94;
}
[data-theme='theme5'] .ant-btn.ant-btn-link {
  color: #2f54eb;
}
.ant-btn.ant-btn-link:hover {
  border-color: transparent;
}
.ant-btn.ant-btn-link[disabled],
.ant-btn.ant-btn-link[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.special-label.ant-form-item {
  margin-bottom: 0px;
}
.special-label .ant-form-item-label label {
  display: block;
  line-height: 39px;
  height: 39px;
  font-size: 14px;
  text-align: left;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.85);
}
.static-warp {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.static-warp .content {
  flex: 1;
  background-color: #fff;
}
.ant-input:focus {
  box-shadow: none !important;
}
.form-title {
  /*字体 主体色*/
  font-size: 16px;
  text-align: left;
  margin-bottom: 16px;
}
[data-theme='theme'] .form-title {
  color: #1890ff;
}
[data-theme='theme1'] .form-title {
  color: #3b97fe;
}
[data-theme='theme2'] .form-title {
  color: #1173ce;
}
[data-theme='theme3'] .form-title {
  color: #212122;
}
[data-theme='theme4'] .form-title {
  color: #009b94;
}
[data-theme='theme5'] .form-title {
  color: #2f54eb;
}
.form-title .title-sign {
  display: inline-block;
  width: 6px;
  height: 14px;
  /*border 主体色*/
  border-left: 4px solid;
  padding-left: 8px;
}
[data-theme='theme'] .form-title .title-sign {
  border-color: #1890ff;
}
[data-theme='theme1'] .form-title .title-sign {
  border-color: #3b97fe;
}
[data-theme='theme2'] .form-title .title-sign {
  border-color: #1173ce;
}
[data-theme='theme3'] .form-title .title-sign {
  border-color: #212122;
}
[data-theme='theme4'] .form-title .title-sign {
  border-color: #009b94;
}
[data-theme='theme5'] .form-title .title-sign {
  border-color: #2f54eb;
}
.ant-tabs-nav .ant-tabs-tab-active.ant-tabs-tab::after {
  position: absolute;
  top: calc(100% - 4px);
  left: 16px;
  width: calc(100% - 32px);
  border-top: 2px solid #1890ff;
  content: '';
  pointer-events: none;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
}
.ant-tabs-card-bar .ant-tabs-tab-active.ant-tabs-tab::after {
  display: none;
}
.ant-table-thead tr:nth-of-type(1) {
  line-height: 24px;
}
.ant-table-thead tr:nth-of-type(1) th {
  padding: 12px 16px !important;
}
.ant-table-thead tr:nth-of-type(2) th {
  font-size: 12px;
  color: #606266;
  line-height: 20px;
  padding: 5px 8px !important;
}
.ant-table-tbody > tr > td {
  padding: 12px 16px !important;
}
.ant-table-fixed-right {
  border-top: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
.ant-table-fixed-left {
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
}
.odd {
  background: #f7f8f9;
}
.sub-title {
  font-weight: 600;
}
.description .desc-divider {
  margin: 5px 0;
}
.description .desc-title {
  position: relative;
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
}
.description .desc-title.default {
  color: #1890ff;
}
.description .desc-title.default .ant-tag {
  color: #1890ff;
  background: rgba(63, 142, 215, 0.1);
  border: 1px solid #1890ff;
}
.description .desc-title.default::before {
  background-color: #1890ff;
}
.description .desc-title.warning {
  color: #f26f3e;
}
.description .desc-title.warning .ant-tag {
  color: #f26f3e;
  background: rgba(242, 111, 62, 0.1);
  border: 1px solid #f26f3e;
}
.description .desc-title.warning::before {
  background-color: #f26f3e;
}
.description .desc-title.danger {
  color: #e83426;
}
.description .desc-title.danger .ant-tag {
  color: #e83426;
  background: rgba(232, 52, 38, 0.1);
  border: 1px solid #e83426;
}
.description .desc-title.danger::before {
  background-color: #e83426;
}
.description .desc-title.success {
  color: #32b082;
}
.description .desc-title.success .ant-tag {
  color: #32b082;
  background: rgba(50, 176, 130, 0.1);
  border: 1px solid #32b082;
}
.description .desc-title.success::before {
  background-color: #32b082;
}
.description .desc-title.notice {
  color: #e7aa4c;
}
.description .desc-title.notice .ant-tag {
  color: #e7aa4c;
  background: #fff6d9;
  border: 1px solid #e7aa4c;
}
.description .desc-title.notice::before {
  background-color: #e7aa4c;
}
.description .desc-title.info {
  color: #909399;
}
.description .desc-title.info .ant-tag {
  color: #909399;
  background: #cdcfd3;
  border: 1px solid #909399;
}
.description .desc-title.info::before {
  background-color: #909399;
}
.description .ant-tag.default {
  color: #1890ff;
  background: rgba(63, 142, 215, 0.1);
  border: 1px solid #1890ff;
}
.description .ant-tag.warning {
  color: #f26f3e;
  background: rgba(242, 111, 62, 0.1);
  border: 1px solid #f26f3e;
}
.description .ant-tag.danger {
  color: #e83426;
  background: rgba(232, 52, 38, 0.1);
  border: 1px solid #e83426;
}
.description .ant-tag.success {
  color: #32b082;
  background: rgba(50, 176, 130, 0.1);
  border: 1px solid #32b082;
}
.description .ant-tag.notice {
  color: #e7aa4c;
  background: #fff6d9;
  border: 1px solid #e7aa4c;
}
.description .ant-tag.info {
  color: #909399;
  background: #cdcfd3;
  border: 1px solid #909399;
}
.description .desc-title.safe-1 {
  color: #1890ff;
}
.description .desc-title.safe-1 .ant-tag {
  color: #1890ff;
  background: rgba(110, 158, 255, 0.1);
  border: 1px solid #1890ff;
}
.description .desc-title.safe-1::before {
  background-color: #1890ff;
}
.description .desc-title.safe-2 {
  color: #ff807c;
}
.description .desc-title.safe-2 .ant-tag {
  color: #ff807c;
  background: rgba(255, 128, 124, 0.1);
  border: 1px solid #ff807c;
}
.description .desc-title.safe-2::before {
  background-color: #ff807c;
}
.description .desc-title.safe-3 {
  color: #7bda70;
}
.description .desc-title.safe-3 .ant-tag {
  color: #7bda70;
  background: rgba(123, 218, 112, 0.1);
  border: 1px solid #7bda70;
}
.description .desc-title.safe-3::before {
  background-color: #7bda70;
}
.description .desc-title.safe-4 {
  color: #ffc838;
}
.description .desc-title.safe-4 .ant-tag {
  color: #ffc838;
  background: rgba(255, 200, 56, 0.1);
  border: 1px solid #ffc838;
}
.description .desc-title.safe-4::before {
  background-color: #ffc838;
}
.description .desc-title.safe-5 {
  color: #ffa038;
}
.description .desc-title.safe-5 .ant-tag {
  color: #ffa038;
  background: rgba(255, 160, 56, 0.1);
  border: 1px solid #ffa038;
}
.description .desc-title.safe-5::before {
  background-color: #ffa038;
}
.description .desc-title.safe-6 {
  color: #c5c9ce;
}
.description .desc-title.safe-6 .ant-tag {
  color: #c5c9ce;
  background: rgba(197, 201, 206, 0.1);
  border: 1px solid #c5c9ce;
}
.description .desc-title.safe-6::before {
  background-color: #c5c9ce;
}
.description .desc-title.safe-7 {
  color: #54b8fe;
}
.description .desc-title.safe-7 .ant-tag {
  color: #54b8fe;
  background: rgba(84, 184, 254, 0.1);
  border: 1px solid #54b8fe;
}
.description .desc-title.safe-7::before {
  background-color: #54b8fe;
}
.description .desc-content {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin: 6px 0;
}
.description .desc-content .desc-content-label {
  display: inline-flex;
  font-size: 14px;
  text-align: left;
  color: #303133;
  line-height: 22px;
}
.description .desc-content .desc-content-value {
  display: inline-flex;
  flex: 1;
  font-size: 14px;
  color: #303133;
  line-height: 22px;
  word-break: break-word;
  overflow-wrap: break-word;
  flex-wrap: wrap;
}
.ant-table-footer::before {
  top: 0;
}
.tag-checkbox .ant-checkbox {
  display: none;
}
.tag-checkbox .ant-checkbox-wrapper {
  min-width: 50px;
  text-align: center;
  line-height: 24px;
}
.tag-checkbox .ant-checkbox-wrapper .ant-checkbox + span {
  padding: 5px 8px;
}
.tag-checkbox .ant-checkbox-wrapper:hover {
  /*字体 主体色*/
}
[data-theme='theme'] .tag-checkbox .ant-checkbox-wrapper:hover {
  color: #1890ff;
}
[data-theme='theme1'] .tag-checkbox .ant-checkbox-wrapper:hover {
  color: #3b97fe;
}
[data-theme='theme2'] .tag-checkbox .ant-checkbox-wrapper:hover {
  color: #1173ce;
}
[data-theme='theme3'] .tag-checkbox .ant-checkbox-wrapper:hover {
  color: #212122;
}
[data-theme='theme4'] .tag-checkbox .ant-checkbox-wrapper:hover {
  color: #009b94;
}
[data-theme='theme5'] .tag-checkbox .ant-checkbox-wrapper:hover {
  color: #2f54eb;
}
.tag-checkbox .ant-checkbox-wrapper-checked {
  /*字体 主体色*/
  /*背景色*/
}
[data-theme='theme'] .tag-checkbox .ant-checkbox-wrapper-checked {
  color: #1890ff;
}
[data-theme='theme1'] .tag-checkbox .ant-checkbox-wrapper-checked {
  color: #3b97fe;
}
[data-theme='theme2'] .tag-checkbox .ant-checkbox-wrapper-checked {
  color: #1173ce;
}
[data-theme='theme3'] .tag-checkbox .ant-checkbox-wrapper-checked {
  color: #212122;
}
[data-theme='theme4'] .tag-checkbox .ant-checkbox-wrapper-checked {
  color: #009b94;
}
[data-theme='theme5'] .tag-checkbox .ant-checkbox-wrapper-checked {
  color: #2f54eb;
}
[data-theme='theme'] .tag-checkbox .ant-checkbox-wrapper-checked {
  background-color: rgba(24, 144, 255, 0.1);
}
[data-theme='theme1'] .tag-checkbox .ant-checkbox-wrapper-checked {
  background-color: rgba(59, 151, 254, 0.1);
}
[data-theme='theme2'] .tag-checkbox .ant-checkbox-wrapper-checked {
  background-color: rgba(17, 115, 206, 0.1);
}
[data-theme='theme3'] .tag-checkbox .ant-checkbox-wrapper-checked {
  background-color: rgba(48, 87, 157, 0.1);
}
[data-theme='theme4'] .tag-checkbox .ant-checkbox-wrapper-checked {
  background-color: rgba(0, 155, 148, 0.1);
}
[data-theme='theme5'] .tag-checkbox .ant-checkbox-wrapper-checked {
  background-color: #dde3ff;
}
*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #c0c4cc;
}
*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset -1px 0 0 0 #ebeef5, inset 1px 0 0 0 #ebeef5;
  background: #ebeef5;
}
*::-webkit-scrollbar-thumb:hover {
  /*滚动条里面轨道*/
  background: #606266;
}
.ant-form-item-label {
  line-height: 32px;
}
.ant-form-item-control {
  line-height: 32px;
}
.ant-form-item-control .ant-input-search {
  margin-top: -2px;
}
.ant-form-item-children {
  line-height: 35px;
  display: flex;
  align-items: center;
}
.hz-timeline .ant-timeline-item-head-blue {
  background-color: #1890ff;
}
.hz-timeline .ant-timeline-item-head {
  width: 12px;
  height: 12px;
  border-color: transparent;
}
.hz-timeline .ant-timeline-item-tail {
  top: 16px;
  left: 5px;
  height: calc(100% - 20px);
  border-left: 2px solid #1890ff;
}
.hz-timeline .ant-timeline-item-content {
  margin-left: 50px;
}
.ant-timeline-item {
  padding: 0 0 24px;
}
.hz-anchor .ant-anchor-ink-ball {
  width: 2px;
  height: 24px;
  margin-top: -8px;
  background-color: #1890ff;
  border: none;
}
.ant-modal-body {
  padding: 24px 48px;
}
.ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
}
.ant-table-fixed-right {
  box-shadow: -2px 0 6px 0px rgba(0, 0, 0, 0.1);
}
.ant-table-middle.ant-table-scroll-position-right:not(.ant-table-scroll-position-left) .ant-table-fixed-left,
.ant-table-middle.ant-table-scroll-position-middle:not(.ant-table-scroll-position-left) .ant-table-fixed-left {
  box-shadow: 2px 0 6px 0px rgba(0, 0, 0, 0.1) !important;
}
.ant-table-thead tr:nth-of-type(1) {
  line-height: 1.65;
}
.ant-table {
  line-height: 1.45;
}
.ant-table-plus-card .ant-card-body {
  padding-top: 0px;
  padding-bottom: 16px;
}
.ant-card-body-border {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0;
}
.ant-card-body-border .ant-card-head-title {
  padding: 0;
}
.ant-card-body-border > div:nth-child(2) {
  padding: 0;
  border-top: 1px solid #ebeef5;
}
.ant-card-body-border .ant-card-body {
  display: flex;
  flex: 1 1 auto;
  min-height: 0;
  width: 100%;
}
.ant-card-body-border .ant-card-extra {
  padding: 0;
}
.min-height-0 {
  min-height: 0;
}
.min-height-0 .ant-card-body {
  min-height: 0;
}
.ant-form-plus-card .ant-card-head-title {
  font-weight: bold;
}
.ant-form-plus-card .ant-card-head {
  border-bottom: 1px solid #ebeef5;
}
.ant-form-plus-card .ant-card-body {
  padding-bottom: 8px;
}
.ant-detail-plus-card .ant-card-head {
  border-bottom: 1px solid transparent;
}
.ant-detail-plus-card .ant-card-body {
  padding-top: 0px;
}
.ant-detail-plus-card .ant-detail-card-title {
  font-weight: bold;
  line-height: 32px;
}
.ant-detail-plus-card .ant-card-head-title {
  padding: 12px 0;
}
.ant-modal-wrap > .ant-modal > .ant-modal-content :last-child.ant-modal-body {
  max-height: calc(700px - 55px);
  overflow: auto;
}
.ant-modal-wrap > .ant-modal > .ant-modal-content > .ant-modal-body {
  max-height: calc(700px - 108px);
  overflow: auto;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: rgba(230, 247, 255, 0.6);
}
.ant-tooltip-inner {
  color: #606266;
}
.ant-tooltip {
  font-size: 12px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.ant-hz-tag-gray {
  background-color: rgba(141, 148, 160, 0.16);
  color: #8d94a0;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  font-size: 12px;
}
.ant-hz-tag-blue {
  background-color: rgba(24, 144, 255, 0.16);
  color: #1890ff;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  font-size: 12px;
}
.ant-hz-tag-red {
  background-color: rgba(245, 34, 45, 0.16);
  color: #f5222d;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  font-size: 12px;
}
.ant-hz-tag-purple {
  background-color: rgba(235, 47, 150, 0.16);
  color: #eb2f96;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  font-size: 12px;
}
.ant-hz-tag-yellow {
  background-color: rgba(250, 140, 22, 0.16);
  color: #fa8c16;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  font-size: 12px;
}
.ant-hz-tag-green {
  background-color: rgba(82, 196, 26, 0.16);
  color: #52c41a;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  font-size: 12px;
}
.ant-hz-tag-orange {
  background-color: #faeee4;
  color: #c8732d;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  font-size: 12px;
}
.hz-line-l {
  border-left: 1px solid #ebeef5;
}
.hz-line-t {
  border-top: 1px solid #ebeef5;
}
.hz-line-r {
  border-right: 1px solid #ebeef5;
}
.hz-line-b {
  border-bottom: 1px solid #ebeef5;
}
.affix-box {
  z-index: 99;
}
.title-tabs .ant-tabs-bar,
.title-tabs .ant-tabs-nav-container,
.title-tabs .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.title-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #f2f4f7;
}
.title-tabs .ant-tabs-nav .ant-tabs-tab-active.ant-tabs-tab::after {
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  width: 100%;
  border-top: 2px solid #1890ff;
  content: '';
  pointer-events: none;
}
.title-tabs .ant-tabs-nav .ant-tabs-tab {
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  margin-right: 0;
  margin-bottom: 0;
  font-size: 14px;
}
.p-t-1 {
  padding-top: 1px;
}
.p-b-1 {
  padding-bottom: 1px;
}
.p-l-1 {
  padding-left: 1px;
}
.p-r-1 {
  padding-right: 1px;
}
.p-v-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}
.p-h-1 {
  padding-left: 1px;
  padding-right: 1px;
}
.p-1 {
  padding: 1px;
}
.p-t-2 {
  padding-top: 2px;
}
.p-b-2 {
  padding-bottom: 2px;
}
.p-l-2 {
  padding-left: 2px;
}
.p-r-2 {
  padding-right: 2px;
}
.p-v-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.p-h-2 {
  padding-left: 2px;
  padding-right: 2px;
}
.p-2 {
  padding: 2px;
}
.p-t-3 {
  padding-top: 3px;
}
.p-b-3 {
  padding-bottom: 3px;
}
.p-l-3 {
  padding-left: 3px;
}
.p-r-3 {
  padding-right: 3px;
}
.p-v-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.p-h-3 {
  padding-left: 3px;
  padding-right: 3px;
}
.p-3 {
  padding: 3px;
}
.p-t-4 {
  padding-top: 4px;
}
.p-b-4 {
  padding-bottom: 4px;
}
.p-l-4 {
  padding-left: 4px;
}
.p-r-4 {
  padding-right: 4px;
}
.p-v-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.p-h-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.p-4 {
  padding: 4px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.p-h-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.p-5 {
  padding: 5px;
}
.p-t-6 {
  padding-top: 6px;
}
.p-b-6 {
  padding-bottom: 6px;
}
.p-l-6 {
  padding-left: 6px;
}
.p-r-6 {
  padding-right: 6px;
}
.p-v-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.p-h-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.p-6 {
  padding: 6px;
}
.p-t-7 {
  padding-top: 7px;
}
.p-b-7 {
  padding-bottom: 7px;
}
.p-l-7 {
  padding-left: 7px;
}
.p-r-7 {
  padding-right: 7px;
}
.p-v-7 {
  padding-top: 7px;
  padding-bottom: 7px;
}
.p-h-7 {
  padding-left: 7px;
  padding-right: 7px;
}
.p-7 {
  padding: 7px;
}
.p-t-8 {
  padding-top: 8px;
}
.p-b-8 {
  padding-bottom: 8px;
}
.p-l-8 {
  padding-left: 8px;
}
.p-r-8 {
  padding-right: 8px;
}
.p-v-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.p-h-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.p-8 {
  padding: 8px;
}
.p-t-9 {
  padding-top: 9px;
}
.p-b-9 {
  padding-bottom: 9px;
}
.p-l-9 {
  padding-left: 9px;
}
.p-r-9 {
  padding-right: 9px;
}
.p-v-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.p-h-9 {
  padding-left: 9px;
  padding-right: 9px;
}
.p-9 {
  padding: 9px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.p-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-10 {
  padding: 10px;
}
.p-t-11 {
  padding-top: 11px;
}
.p-b-11 {
  padding-bottom: 11px;
}
.p-l-11 {
  padding-left: 11px;
}
.p-r-11 {
  padding-right: 11px;
}
.p-v-11 {
  padding-top: 11px;
  padding-bottom: 11px;
}
.p-h-11 {
  padding-left: 11px;
  padding-right: 11px;
}
.p-11 {
  padding: 11px;
}
.p-t-12 {
  padding-top: 12px;
}
.p-b-12 {
  padding-bottom: 12px;
}
.p-l-12 {
  padding-left: 12px;
}
.p-r-12 {
  padding-right: 12px;
}
.p-v-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.p-h-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.p-12 {
  padding: 12px;
}
.p-t-13 {
  padding-top: 13px;
}
.p-b-13 {
  padding-bottom: 13px;
}
.p-l-13 {
  padding-left: 13px;
}
.p-r-13 {
  padding-right: 13px;
}
.p-v-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}
.p-h-13 {
  padding-left: 13px;
  padding-right: 13px;
}
.p-13 {
  padding: 13px;
}
.p-t-14 {
  padding-top: 14px;
}
.p-b-14 {
  padding-bottom: 14px;
}
.p-l-14 {
  padding-left: 14px;
}
.p-r-14 {
  padding-right: 14px;
}
.p-v-14 {
  padding-top: 14px;
  padding-bottom: 14px;
}
.p-h-14 {
  padding-left: 14px;
  padding-right: 14px;
}
.p-14 {
  padding: 14px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.p-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.p-15 {
  padding: 15px;
}
.p-t-16 {
  padding-top: 16px;
}
.p-b-16 {
  padding-bottom: 16px;
}
.p-l-16 {
  padding-left: 16px;
}
.p-r-16 {
  padding-right: 16px;
}
.p-v-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.p-h-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.p-16 {
  padding: 16px;
}
.p-t-17 {
  padding-top: 17px;
}
.p-b-17 {
  padding-bottom: 17px;
}
.p-l-17 {
  padding-left: 17px;
}
.p-r-17 {
  padding-right: 17px;
}
.p-v-17 {
  padding-top: 17px;
  padding-bottom: 17px;
}
.p-h-17 {
  padding-left: 17px;
  padding-right: 17px;
}
.p-17 {
  padding: 17px;
}
.p-t-18 {
  padding-top: 18px;
}
.p-b-18 {
  padding-bottom: 18px;
}
.p-l-18 {
  padding-left: 18px;
}
.p-r-18 {
  padding-right: 18px;
}
.p-v-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}
.p-h-18 {
  padding-left: 18px;
  padding-right: 18px;
}
.p-18 {
  padding: 18px;
}
.p-t-19 {
  padding-top: 19px;
}
.p-b-19 {
  padding-bottom: 19px;
}
.p-l-19 {
  padding-left: 19px;
}
.p-r-19 {
  padding-right: 19px;
}
.p-v-19 {
  padding-top: 19px;
  padding-bottom: 19px;
}
.p-h-19 {
  padding-left: 19px;
  padding-right: 19px;
}
.p-19 {
  padding: 19px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.p-h-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.p-20 {
  padding: 20px;
}
.p-t-21 {
  padding-top: 21px;
}
.p-b-21 {
  padding-bottom: 21px;
}
.p-l-21 {
  padding-left: 21px;
}
.p-r-21 {
  padding-right: 21px;
}
.p-v-21 {
  padding-top: 21px;
  padding-bottom: 21px;
}
.p-h-21 {
  padding-left: 21px;
  padding-right: 21px;
}
.p-21 {
  padding: 21px;
}
.p-t-22 {
  padding-top: 22px;
}
.p-b-22 {
  padding-bottom: 22px;
}
.p-l-22 {
  padding-left: 22px;
}
.p-r-22 {
  padding-right: 22px;
}
.p-v-22 {
  padding-top: 22px;
  padding-bottom: 22px;
}
.p-h-22 {
  padding-left: 22px;
  padding-right: 22px;
}
.p-22 {
  padding: 22px;
}
.p-t-23 {
  padding-top: 23px;
}
.p-b-23 {
  padding-bottom: 23px;
}
.p-l-23 {
  padding-left: 23px;
}
.p-r-23 {
  padding-right: 23px;
}
.p-v-23 {
  padding-top: 23px;
  padding-bottom: 23px;
}
.p-h-23 {
  padding-left: 23px;
  padding-right: 23px;
}
.p-23 {
  padding: 23px;
}
.p-t-24 {
  padding-top: 24px;
}
.p-b-24 {
  padding-bottom: 24px;
}
.p-l-24 {
  padding-left: 24px;
}
.p-r-24 {
  padding-right: 24px;
}
.p-v-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.p-h-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.p-24 {
  padding: 24px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-v-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.p-h-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.p-25 {
  padding: 25px;
}
.p-t-26 {
  padding-top: 26px;
}
.p-b-26 {
  padding-bottom: 26px;
}
.p-l-26 {
  padding-left: 26px;
}
.p-r-26 {
  padding-right: 26px;
}
.p-v-26 {
  padding-top: 26px;
  padding-bottom: 26px;
}
.p-h-26 {
  padding-left: 26px;
  padding-right: 26px;
}
.p-26 {
  padding: 26px;
}
.p-t-27 {
  padding-top: 27px;
}
.p-b-27 {
  padding-bottom: 27px;
}
.p-l-27 {
  padding-left: 27px;
}
.p-r-27 {
  padding-right: 27px;
}
.p-v-27 {
  padding-top: 27px;
  padding-bottom: 27px;
}
.p-h-27 {
  padding-left: 27px;
  padding-right: 27px;
}
.p-27 {
  padding: 27px;
}
.p-t-28 {
  padding-top: 28px;
}
.p-b-28 {
  padding-bottom: 28px;
}
.p-l-28 {
  padding-left: 28px;
}
.p-r-28 {
  padding-right: 28px;
}
.p-v-28 {
  padding-top: 28px;
  padding-bottom: 28px;
}
.p-h-28 {
  padding-left: 28px;
  padding-right: 28px;
}
.p-28 {
  padding: 28px;
}
.p-t-29 {
  padding-top: 29px;
}
.p-b-29 {
  padding-bottom: 29px;
}
.p-l-29 {
  padding-left: 29px;
}
.p-r-29 {
  padding-right: 29px;
}
.p-v-29 {
  padding-top: 29px;
  padding-bottom: 29px;
}
.p-h-29 {
  padding-left: 29px;
  padding-right: 29px;
}
.p-29 {
  padding: 29px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-v-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.p-h-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.p-30 {
  padding: 30px;
}
.p-t-31 {
  padding-top: 31px;
}
.p-b-31 {
  padding-bottom: 31px;
}
.p-l-31 {
  padding-left: 31px;
}
.p-r-31 {
  padding-right: 31px;
}
.p-v-31 {
  padding-top: 31px;
  padding-bottom: 31px;
}
.p-h-31 {
  padding-left: 31px;
  padding-right: 31px;
}
.p-31 {
  padding: 31px;
}
.p-t-32 {
  padding-top: 32px;
}
.p-b-32 {
  padding-bottom: 32px;
}
.p-l-32 {
  padding-left: 32px;
}
.p-r-32 {
  padding-right: 32px;
}
.p-v-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.p-h-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.p-32 {
  padding: 32px;
}
.p-t-33 {
  padding-top: 33px;
}
.p-b-33 {
  padding-bottom: 33px;
}
.p-l-33 {
  padding-left: 33px;
}
.p-r-33 {
  padding-right: 33px;
}
.p-v-33 {
  padding-top: 33px;
  padding-bottom: 33px;
}
.p-h-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.p-33 {
  padding: 33px;
}
.p-t-34 {
  padding-top: 34px;
}
.p-b-34 {
  padding-bottom: 34px;
}
.p-l-34 {
  padding-left: 34px;
}
.p-r-34 {
  padding-right: 34px;
}
.p-v-34 {
  padding-top: 34px;
  padding-bottom: 34px;
}
.p-h-34 {
  padding-left: 34px;
  padding-right: 34px;
}
.p-34 {
  padding: 34px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-r-35 {
  padding-right: 35px;
}
.p-v-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.p-h-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.p-35 {
  padding: 35px;
}
.p-t-36 {
  padding-top: 36px;
}
.p-b-36 {
  padding-bottom: 36px;
}
.p-l-36 {
  padding-left: 36px;
}
.p-r-36 {
  padding-right: 36px;
}
.p-v-36 {
  padding-top: 36px;
  padding-bottom: 36px;
}
.p-h-36 {
  padding-left: 36px;
  padding-right: 36px;
}
.p-36 {
  padding: 36px;
}
.p-t-37 {
  padding-top: 37px;
}
.p-b-37 {
  padding-bottom: 37px;
}
.p-l-37 {
  padding-left: 37px;
}
.p-r-37 {
  padding-right: 37px;
}
.p-v-37 {
  padding-top: 37px;
  padding-bottom: 37px;
}
.p-h-37 {
  padding-left: 37px;
  padding-right: 37px;
}
.p-37 {
  padding: 37px;
}
.p-t-38 {
  padding-top: 38px;
}
.p-b-38 {
  padding-bottom: 38px;
}
.p-l-38 {
  padding-left: 38px;
}
.p-r-38 {
  padding-right: 38px;
}
.p-v-38 {
  padding-top: 38px;
  padding-bottom: 38px;
}
.p-h-38 {
  padding-left: 38px;
  padding-right: 38px;
}
.p-38 {
  padding: 38px;
}
.p-t-39 {
  padding-top: 39px;
}
.p-b-39 {
  padding-bottom: 39px;
}
.p-l-39 {
  padding-left: 39px;
}
.p-r-39 {
  padding-right: 39px;
}
.p-v-39 {
  padding-top: 39px;
  padding-bottom: 39px;
}
.p-h-39 {
  padding-left: 39px;
  padding-right: 39px;
}
.p-39 {
  padding: 39px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-r-40 {
  padding-right: 40px;
}
.p-v-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.p-h-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.p-40 {
  padding: 40px;
}
.p-t-41 {
  padding-top: 41px;
}
.p-b-41 {
  padding-bottom: 41px;
}
.p-l-41 {
  padding-left: 41px;
}
.p-r-41 {
  padding-right: 41px;
}
.p-v-41 {
  padding-top: 41px;
  padding-bottom: 41px;
}
.p-h-41 {
  padding-left: 41px;
  padding-right: 41px;
}
.p-41 {
  padding: 41px;
}
.p-t-42 {
  padding-top: 42px;
}
.p-b-42 {
  padding-bottom: 42px;
}
.p-l-42 {
  padding-left: 42px;
}
.p-r-42 {
  padding-right: 42px;
}
.p-v-42 {
  padding-top: 42px;
  padding-bottom: 42px;
}
.p-h-42 {
  padding-left: 42px;
  padding-right: 42px;
}
.p-42 {
  padding: 42px;
}
.p-t-43 {
  padding-top: 43px;
}
.p-b-43 {
  padding-bottom: 43px;
}
.p-l-43 {
  padding-left: 43px;
}
.p-r-43 {
  padding-right: 43px;
}
.p-v-43 {
  padding-top: 43px;
  padding-bottom: 43px;
}
.p-h-43 {
  padding-left: 43px;
  padding-right: 43px;
}
.p-43 {
  padding: 43px;
}
.p-t-44 {
  padding-top: 44px;
}
.p-b-44 {
  padding-bottom: 44px;
}
.p-l-44 {
  padding-left: 44px;
}
.p-r-44 {
  padding-right: 44px;
}
.p-v-44 {
  padding-top: 44px;
  padding-bottom: 44px;
}
.p-h-44 {
  padding-left: 44px;
  padding-right: 44px;
}
.p-44 {
  padding: 44px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-r-45 {
  padding-right: 45px;
}
.p-v-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.p-h-45 {
  padding-left: 45px;
  padding-right: 45px;
}
.p-45 {
  padding: 45px;
}
.p-t-46 {
  padding-top: 46px;
}
.p-b-46 {
  padding-bottom: 46px;
}
.p-l-46 {
  padding-left: 46px;
}
.p-r-46 {
  padding-right: 46px;
}
.p-v-46 {
  padding-top: 46px;
  padding-bottom: 46px;
}
.p-h-46 {
  padding-left: 46px;
  padding-right: 46px;
}
.p-46 {
  padding: 46px;
}
.p-t-47 {
  padding-top: 47px;
}
.p-b-47 {
  padding-bottom: 47px;
}
.p-l-47 {
  padding-left: 47px;
}
.p-r-47 {
  padding-right: 47px;
}
.p-v-47 {
  padding-top: 47px;
  padding-bottom: 47px;
}
.p-h-47 {
  padding-left: 47px;
  padding-right: 47px;
}
.p-47 {
  padding: 47px;
}
.p-t-48 {
  padding-top: 48px;
}
.p-b-48 {
  padding-bottom: 48px;
}
.p-l-48 {
  padding-left: 48px;
}
.p-r-48 {
  padding-right: 48px;
}
.p-v-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.p-h-48 {
  padding-left: 48px;
  padding-right: 48px;
}
.p-48 {
  padding: 48px;
}
.p-t-49 {
  padding-top: 49px;
}
.p-b-49 {
  padding-bottom: 49px;
}
.p-l-49 {
  padding-left: 49px;
}
.p-r-49 {
  padding-right: 49px;
}
.p-v-49 {
  padding-top: 49px;
  padding-bottom: 49px;
}
.p-h-49 {
  padding-left: 49px;
  padding-right: 49px;
}
.p-49 {
  padding: 49px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-r-50 {
  padding-right: 50px;
}
.p-v-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.p-h-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.p-50 {
  padding: 50px;
}
.m-t-1 {
  margin-top: 1px;
}
.m-b-1 {
  margin-bottom: 1px;
}
.m-l-1 {
  margin-left: 1px;
}
.m-r-1 {
  margin-right: 1px;
}
.m-v-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}
.m-h-1 {
  margin-left: 1px;
  margin-right: 1px;
}
.m-1 {
  margin: 1px;
}
.m-t-2 {
  margin-top: 2px;
}
.m-b-2 {
  margin-bottom: 2px;
}
.m-l-2 {
  margin-left: 2px;
}
.m-r-2 {
  margin-right: 2px;
}
.m-v-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.m-h-2 {
  margin-left: 2px;
  margin-right: 2px;
}
.m-2 {
  margin: 2px;
}
.m-t-3 {
  margin-top: 3px;
}
.m-b-3 {
  margin-bottom: 3px;
}
.m-l-3 {
  margin-left: 3px;
}
.m-r-3 {
  margin-right: 3px;
}
.m-v-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}
.m-h-3 {
  margin-left: 3px;
  margin-right: 3px;
}
.m-3 {
  margin: 3px;
}
.m-t-4 {
  margin-top: 4px;
}
.m-b-4 {
  margin-bottom: 4px;
}
.m-l-4 {
  margin-left: 4px;
}
.m-r-4 {
  margin-right: 4px;
}
.m-v-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.m-h-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.m-4 {
  margin: 4px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-v-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.m-h-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.m-5 {
  margin: 5px;
}
.m-t-6 {
  margin-top: 6px;
}
.m-b-6 {
  margin-bottom: 6px;
}
.m-l-6 {
  margin-left: 6px;
}
.m-r-6 {
  margin-right: 6px;
}
.m-v-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.m-h-6 {
  margin-left: 6px;
  margin-right: 6px;
}
.m-6 {
  margin: 6px;
}
.m-t-7 {
  margin-top: 7px;
}
.m-b-7 {
  margin-bottom: 7px;
}
.m-l-7 {
  margin-left: 7px;
}
.m-r-7 {
  margin-right: 7px;
}
.m-v-7 {
  margin-top: 7px;
  margin-bottom: 7px;
}
.m-h-7 {
  margin-left: 7px;
  margin-right: 7px;
}
.m-7 {
  margin: 7px;
}
.m-t-8 {
  margin-top: 8px;
}
.m-b-8 {
  margin-bottom: 8px;
}
.m-l-8 {
  margin-left: 8px;
}
.m-r-8 {
  margin-right: 8px;
}
.m-v-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.m-h-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.m-8 {
  margin: 8px;
}
.m-t-9 {
  margin-top: 9px;
}
.m-b-9 {
  margin-bottom: 9px;
}
.m-l-9 {
  margin-left: 9px;
}
.m-r-9 {
  margin-right: 9px;
}
.m-v-9 {
  margin-top: 9px;
  margin-bottom: 9px;
}
.m-h-9 {
  margin-left: 9px;
  margin-right: 9px;
}
.m-9 {
  margin: 9px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-v-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.m-h-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.m-10 {
  margin: 10px;
}
.m-t-11 {
  margin-top: 11px;
}
.m-b-11 {
  margin-bottom: 11px;
}
.m-l-11 {
  margin-left: 11px;
}
.m-r-11 {
  margin-right: 11px;
}
.m-v-11 {
  margin-top: 11px;
  margin-bottom: 11px;
}
.m-h-11 {
  margin-left: 11px;
  margin-right: 11px;
}
.m-11 {
  margin: 11px;
}
.m-t-12 {
  margin-top: 12px;
}
.m-b-12 {
  margin-bottom: 12px;
}
.m-l-12 {
  margin-left: 12px;
}
.m-r-12 {
  margin-right: 12px;
}
.m-v-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.m-h-12 {
  margin-left: 12px;
  margin-right: 12px;
}
.m-12 {
  margin: 12px;
}
.m-t-13 {
  margin-top: 13px;
}
.m-b-13 {
  margin-bottom: 13px;
}
.m-l-13 {
  margin-left: 13px;
}
.m-r-13 {
  margin-right: 13px;
}
.m-v-13 {
  margin-top: 13px;
  margin-bottom: 13px;
}
.m-h-13 {
  margin-left: 13px;
  margin-right: 13px;
}
.m-13 {
  margin: 13px;
}
.m-t-14 {
  margin-top: 14px;
}
.m-b-14 {
  margin-bottom: 14px;
}
.m-l-14 {
  margin-left: 14px;
}
.m-r-14 {
  margin-right: 14px;
}
.m-v-14 {
  margin-top: 14px;
  margin-bottom: 14px;
}
.m-h-14 {
  margin-left: 14px;
  margin-right: 14px;
}
.m-14 {
  margin: 14px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-v-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.m-h-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.m-15 {
  margin: 15px;
}
.m-t-16 {
  margin-top: 16px;
}
.m-b-16 {
  margin-bottom: 16px;
}
.m-l-16 {
  margin-left: 16px;
}
.m-r-16 {
  margin-right: 16px;
}
.m-v-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.m-h-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.m-16 {
  margin: 16px;
}
.m-t-17 {
  margin-top: 17px;
}
.m-b-17 {
  margin-bottom: 17px;
}
.m-l-17 {
  margin-left: 17px;
}
.m-r-17 {
  margin-right: 17px;
}
.m-v-17 {
  margin-top: 17px;
  margin-bottom: 17px;
}
.m-h-17 {
  margin-left: 17px;
  margin-right: 17px;
}
.m-17 {
  margin: 17px;
}
.m-t-18 {
  margin-top: 18px;
}
.m-b-18 {
  margin-bottom: 18px;
}
.m-l-18 {
  margin-left: 18px;
}
.m-r-18 {
  margin-right: 18px;
}
.m-v-18 {
  margin-top: 18px;
  margin-bottom: 18px;
}
.m-h-18 {
  margin-left: 18px;
  margin-right: 18px;
}
.m-18 {
  margin: 18px;
}
.m-t-19 {
  margin-top: 19px;
}
.m-b-19 {
  margin-bottom: 19px;
}
.m-l-19 {
  margin-left: 19px;
}
.m-r-19 {
  margin-right: 19px;
}
.m-v-19 {
  margin-top: 19px;
  margin-bottom: 19px;
}
.m-h-19 {
  margin-left: 19px;
  margin-right: 19px;
}
.m-19 {
  margin: 19px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-v-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.m-h-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.m-20 {
  margin: 20px;
}
.m-t-21 {
  margin-top: 21px;
}
.m-b-21 {
  margin-bottom: 21px;
}
.m-l-21 {
  margin-left: 21px;
}
.m-r-21 {
  margin-right: 21px;
}
.m-v-21 {
  margin-top: 21px;
  margin-bottom: 21px;
}
.m-h-21 {
  margin-left: 21px;
  margin-right: 21px;
}
.m-21 {
  margin: 21px;
}
.m-t-22 {
  margin-top: 22px;
}
.m-b-22 {
  margin-bottom: 22px;
}
.m-l-22 {
  margin-left: 22px;
}
.m-r-22 {
  margin-right: 22px;
}
.m-v-22 {
  margin-top: 22px;
  margin-bottom: 22px;
}
.m-h-22 {
  margin-left: 22px;
  margin-right: 22px;
}
.m-22 {
  margin: 22px;
}
.m-t-23 {
  margin-top: 23px;
}
.m-b-23 {
  margin-bottom: 23px;
}
.m-l-23 {
  margin-left: 23px;
}
.m-r-23 {
  margin-right: 23px;
}
.m-v-23 {
  margin-top: 23px;
  margin-bottom: 23px;
}
.m-h-23 {
  margin-left: 23px;
  margin-right: 23px;
}
.m-23 {
  margin: 23px;
}
.m-t-24 {
  margin-top: 24px;
}
.m-b-24 {
  margin-bottom: 24px;
}
.m-l-24 {
  margin-left: 24px;
}
.m-r-24 {
  margin-right: 24px;
}
.m-v-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.m-h-24 {
  margin-left: 24px;
  margin-right: 24px;
}
.m-24 {
  margin: 24px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-v-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.m-h-25 {
  margin-left: 25px;
  margin-right: 25px;
}
.m-25 {
  margin: 25px;
}
.m-t-26 {
  margin-top: 26px;
}
.m-b-26 {
  margin-bottom: 26px;
}
.m-l-26 {
  margin-left: 26px;
}
.m-r-26 {
  margin-right: 26px;
}
.m-v-26 {
  margin-top: 26px;
  margin-bottom: 26px;
}
.m-h-26 {
  margin-left: 26px;
  margin-right: 26px;
}
.m-26 {
  margin: 26px;
}
.m-t-27 {
  margin-top: 27px;
}
.m-b-27 {
  margin-bottom: 27px;
}
.m-l-27 {
  margin-left: 27px;
}
.m-r-27 {
  margin-right: 27px;
}
.m-v-27 {
  margin-top: 27px;
  margin-bottom: 27px;
}
.m-h-27 {
  margin-left: 27px;
  margin-right: 27px;
}
.m-27 {
  margin: 27px;
}
.m-t-28 {
  margin-top: 28px;
}
.m-b-28 {
  margin-bottom: 28px;
}
.m-l-28 {
  margin-left: 28px;
}
.m-r-28 {
  margin-right: 28px;
}
.m-v-28 {
  margin-top: 28px;
  margin-bottom: 28px;
}
.m-h-28 {
  margin-left: 28px;
  margin-right: 28px;
}
.m-28 {
  margin: 28px;
}
.m-t-29 {
  margin-top: 29px;
}
.m-b-29 {
  margin-bottom: 29px;
}
.m-l-29 {
  margin-left: 29px;
}
.m-r-29 {
  margin-right: 29px;
}
.m-v-29 {
  margin-top: 29px;
  margin-bottom: 29px;
}
.m-h-29 {
  margin-left: 29px;
  margin-right: 29px;
}
.m-29 {
  margin: 29px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-v-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.m-h-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.m-30 {
  margin: 30px;
}
.m-t-31 {
  margin-top: 31px;
}
.m-b-31 {
  margin-bottom: 31px;
}
.m-l-31 {
  margin-left: 31px;
}
.m-r-31 {
  margin-right: 31px;
}
.m-v-31 {
  margin-top: 31px;
  margin-bottom: 31px;
}
.m-h-31 {
  margin-left: 31px;
  margin-right: 31px;
}
.m-31 {
  margin: 31px;
}
.m-t-32 {
  margin-top: 32px;
}
.m-b-32 {
  margin-bottom: 32px;
}
.m-l-32 {
  margin-left: 32px;
}
.m-r-32 {
  margin-right: 32px;
}
.m-v-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.m-h-32 {
  margin-left: 32px;
  margin-right: 32px;
}
.m-32 {
  margin: 32px;
}
.m-t-33 {
  margin-top: 33px;
}
.m-b-33 {
  margin-bottom: 33px;
}
.m-l-33 {
  margin-left: 33px;
}
.m-r-33 {
  margin-right: 33px;
}
.m-v-33 {
  margin-top: 33px;
  margin-bottom: 33px;
}
.m-h-33 {
  margin-left: 33px;
  margin-right: 33px;
}
.m-33 {
  margin: 33px;
}
.m-t-34 {
  margin-top: 34px;
}
.m-b-34 {
  margin-bottom: 34px;
}
.m-l-34 {
  margin-left: 34px;
}
.m-r-34 {
  margin-right: 34px;
}
.m-v-34 {
  margin-top: 34px;
  margin-bottom: 34px;
}
.m-h-34 {
  margin-left: 34px;
  margin-right: 34px;
}
.m-34 {
  margin: 34px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-r-35 {
  margin-right: 35px;
}
.m-v-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.m-h-35 {
  margin-left: 35px;
  margin-right: 35px;
}
.m-35 {
  margin: 35px;
}
.m-t-36 {
  margin-top: 36px;
}
.m-b-36 {
  margin-bottom: 36px;
}
.m-l-36 {
  margin-left: 36px;
}
.m-r-36 {
  margin-right: 36px;
}
.m-v-36 {
  margin-top: 36px;
  margin-bottom: 36px;
}
.m-h-36 {
  margin-left: 36px;
  margin-right: 36px;
}
.m-36 {
  margin: 36px;
}
.m-t-37 {
  margin-top: 37px;
}
.m-b-37 {
  margin-bottom: 37px;
}
.m-l-37 {
  margin-left: 37px;
}
.m-r-37 {
  margin-right: 37px;
}
.m-v-37 {
  margin-top: 37px;
  margin-bottom: 37px;
}
.m-h-37 {
  margin-left: 37px;
  margin-right: 37px;
}
.m-37 {
  margin: 37px;
}
.m-t-38 {
  margin-top: 38px;
}
.m-b-38 {
  margin-bottom: 38px;
}
.m-l-38 {
  margin-left: 38px;
}
.m-r-38 {
  margin-right: 38px;
}
.m-v-38 {
  margin-top: 38px;
  margin-bottom: 38px;
}
.m-h-38 {
  margin-left: 38px;
  margin-right: 38px;
}
.m-38 {
  margin: 38px;
}
.m-t-39 {
  margin-top: 39px;
}
.m-b-39 {
  margin-bottom: 39px;
}
.m-l-39 {
  margin-left: 39px;
}
.m-r-39 {
  margin-right: 39px;
}
.m-v-39 {
  margin-top: 39px;
  margin-bottom: 39px;
}
.m-h-39 {
  margin-left: 39px;
  margin-right: 39px;
}
.m-39 {
  margin: 39px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-v-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.m-h-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.m-40 {
  margin: 40px;
}
.m-t-41 {
  margin-top: 41px;
}
.m-b-41 {
  margin-bottom: 41px;
}
.m-l-41 {
  margin-left: 41px;
}
.m-r-41 {
  margin-right: 41px;
}
.m-v-41 {
  margin-top: 41px;
  margin-bottom: 41px;
}
.m-h-41 {
  margin-left: 41px;
  margin-right: 41px;
}
.m-41 {
  margin: 41px;
}
.m-t-42 {
  margin-top: 42px;
}
.m-b-42 {
  margin-bottom: 42px;
}
.m-l-42 {
  margin-left: 42px;
}
.m-r-42 {
  margin-right: 42px;
}
.m-v-42 {
  margin-top: 42px;
  margin-bottom: 42px;
}
.m-h-42 {
  margin-left: 42px;
  margin-right: 42px;
}
.m-42 {
  margin: 42px;
}
.m-t-43 {
  margin-top: 43px;
}
.m-b-43 {
  margin-bottom: 43px;
}
.m-l-43 {
  margin-left: 43px;
}
.m-r-43 {
  margin-right: 43px;
}
.m-v-43 {
  margin-top: 43px;
  margin-bottom: 43px;
}
.m-h-43 {
  margin-left: 43px;
  margin-right: 43px;
}
.m-43 {
  margin: 43px;
}
.m-t-44 {
  margin-top: 44px;
}
.m-b-44 {
  margin-bottom: 44px;
}
.m-l-44 {
  margin-left: 44px;
}
.m-r-44 {
  margin-right: 44px;
}
.m-v-44 {
  margin-top: 44px;
  margin-bottom: 44px;
}
.m-h-44 {
  margin-left: 44px;
  margin-right: 44px;
}
.m-44 {
  margin: 44px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-r-45 {
  margin-right: 45px;
}
.m-v-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.m-h-45 {
  margin-left: 45px;
  margin-right: 45px;
}
.m-45 {
  margin: 45px;
}
.m-t-46 {
  margin-top: 46px;
}
.m-b-46 {
  margin-bottom: 46px;
}
.m-l-46 {
  margin-left: 46px;
}
.m-r-46 {
  margin-right: 46px;
}
.m-v-46 {
  margin-top: 46px;
  margin-bottom: 46px;
}
.m-h-46 {
  margin-left: 46px;
  margin-right: 46px;
}
.m-46 {
  margin: 46px;
}
.m-t-47 {
  margin-top: 47px;
}
.m-b-47 {
  margin-bottom: 47px;
}
.m-l-47 {
  margin-left: 47px;
}
.m-r-47 {
  margin-right: 47px;
}
.m-v-47 {
  margin-top: 47px;
  margin-bottom: 47px;
}
.m-h-47 {
  margin-left: 47px;
  margin-right: 47px;
}
.m-47 {
  margin: 47px;
}
.m-t-48 {
  margin-top: 48px;
}
.m-b-48 {
  margin-bottom: 48px;
}
.m-l-48 {
  margin-left: 48px;
}
.m-r-48 {
  margin-right: 48px;
}
.m-v-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.m-h-48 {
  margin-left: 48px;
  margin-right: 48px;
}
.m-48 {
  margin: 48px;
}
.m-t-49 {
  margin-top: 49px;
}
.m-b-49 {
  margin-bottom: 49px;
}
.m-l-49 {
  margin-left: 49px;
}
.m-r-49 {
  margin-right: 49px;
}
.m-v-49 {
  margin-top: 49px;
  margin-bottom: 49px;
}
.m-h-49 {
  margin-left: 49px;
  margin-right: 49px;
}
.m-49 {
  margin: 49px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-r-50 {
  margin-right: 50px;
}
.m-v-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.m-h-50 {
  margin-left: 50px;
  margin-right: 50px;
}
.m-50 {
  margin: 50px;
}
* > .enter-x:nth-child(1) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}
* > .-enter-x:nth-child(1) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}
* > .enter-y:nth-child(1) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}
* > .-enter-y:nth-child(1) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}
* > .enter-x:nth-child(2) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
* > .-enter-x:nth-child(2) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
* > .enter-y:nth-child(2) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
* > .-enter-y:nth-child(2) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
* > .enter-x:nth-child(3) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}
* > .-enter-x:nth-child(3) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}
* > .enter-y:nth-child(3) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}
* > .-enter-y:nth-child(3) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}
* > .enter-x:nth-child(4) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}
* > .-enter-x:nth-child(4) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}
* > .enter-y:nth-child(4) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}
* > .-enter-y:nth-child(4) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}
* > .enter-x:nth-child(5) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}
* > .-enter-x:nth-child(5) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}
* > .enter-y:nth-child(5) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}
* > .-enter-y:nth-child(5) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}
* > .enter-x:nth-child(6) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}
* > .-enter-x:nth-child(6) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}
* > .enter-y:nth-child(6) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}
* > .-enter-y:nth-child(6) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}
* > .enter-x:nth-child(7) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}
* > .-enter-x:nth-child(7) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}
* > .enter-y:nth-child(7) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}
* > .-enter-y:nth-child(7) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}
* > .enter-x:nth-child(8) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}
* > .-enter-x:nth-child(8) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}
* > .enter-y:nth-child(8) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}
* > .-enter-y:nth-child(8) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}
* > .enter-x:nth-child(9) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}
* > .-enter-x:nth-child(9) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}
* > .enter-y:nth-child(9) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}
* > .-enter-y:nth-child(9) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}
* > .enter-x:nth-child(10) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
* > .-enter-x:nth-child(10) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
* > .enter-y:nth-child(10) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
* > .-enter-y:nth-child(10) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
* > .enter-x:nth-child(11) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.1s;
}
* > .-enter-x:nth-child(11) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.1s;
}
* > .enter-y:nth-child(11) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.1s;
}
* > .-enter-y:nth-child(11) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.1s;
}
* > .enter-x:nth-child(12) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}
* > .-enter-x:nth-child(12) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}
* > .enter-y:nth-child(12) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}
* > .-enter-y:nth-child(12) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}
* > .enter-x:nth-child(13) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.3s;
}
* > .-enter-x:nth-child(13) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.3s;
}
* > .enter-y:nth-child(13) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.3s;
}
* > .-enter-y:nth-child(13) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.3s;
}
* > .enter-x:nth-child(14) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}
* > .-enter-x:nth-child(14) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}
* > .enter-y:nth-child(14) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}
* > .-enter-y:nth-child(14) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}
* > .enter-x:nth-child(15) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}
* > .-enter-x:nth-child(15) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}
* > .enter-y:nth-child(15) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}
* > .-enter-y:nth-child(15) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}
* > .enter-x:nth-child(16) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.6s;
}
* > .-enter-x:nth-child(16) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.6s;
}
* > .enter-y:nth-child(16) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.6s;
}
* > .-enter-y:nth-child(16) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.6s;
}
* > .enter-x:nth-child(17) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.7s;
}
* > .-enter-x:nth-child(17) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.7s;
}
* > .enter-y:nth-child(17) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.7s;
}
* > .-enter-y:nth-child(17) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.7s;
}
* > .enter-x:nth-child(18) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}
* > .-enter-x:nth-child(18) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}
* > .enter-y:nth-child(18) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}
* > .-enter-y:nth-child(18) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}
* > .enter-x:nth-child(19) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.9s;
}
* > .-enter-x:nth-child(19) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.9s;
}
* > .enter-y:nth-child(19) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.9s;
}
* > .-enter-y:nth-child(19) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 1.9s;
}
* > .enter-x:nth-child(20) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
* > .-enter-x:nth-child(20) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
* > .enter-y:nth-child(20) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
* > .-enter-y:nth-child(20) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
* > .enter-x:nth-child(21) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.1s;
}
* > .-enter-x:nth-child(21) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.1s;
}
* > .enter-y:nth-child(21) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.1s;
}
* > .-enter-y:nth-child(21) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.1s;
}
* > .enter-x:nth-child(22) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.2s;
}
* > .-enter-x:nth-child(22) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.2s;
}
* > .enter-y:nth-child(22) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.2s;
}
* > .-enter-y:nth-child(22) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.2s;
}
* > .enter-x:nth-child(23) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.3s;
}
* > .-enter-x:nth-child(23) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.3s;
}
* > .enter-y:nth-child(23) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.3s;
}
* > .-enter-y:nth-child(23) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.3s;
}
* > .enter-x:nth-child(24) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.4s;
}
* > .-enter-x:nth-child(24) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.4s;
}
* > .enter-y:nth-child(24) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.4s;
}
* > .-enter-y:nth-child(24) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.4s;
}
* > .enter-x:nth-child(25) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
}
* > .-enter-x:nth-child(25) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
}
* > .enter-y:nth-child(25) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
}
* > .-enter-y:nth-child(25) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
}
* > .enter-x:nth-child(26) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.6s;
}
* > .-enter-x:nth-child(26) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.6s;
}
* > .enter-y:nth-child(26) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.6s;
}
* > .-enter-y:nth-child(26) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.6s;
}
* > .enter-x:nth-child(27) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.7s;
}
* > .-enter-x:nth-child(27) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.7s;
}
* > .enter-y:nth-child(27) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.7s;
}
* > .-enter-y:nth-child(27) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.7s;
}
* > .enter-x:nth-child(28) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.8s;
}
* > .-enter-x:nth-child(28) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.8s;
}
* > .enter-y:nth-child(28) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.8s;
}
* > .-enter-y:nth-child(28) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.8s;
}
* > .enter-x:nth-child(29) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.9s;
}
* > .-enter-x:nth-child(29) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.9s;
}
* > .enter-y:nth-child(29) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.9s;
}
* > .-enter-y:nth-child(29) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2.9s;
}
* > .enter-x:nth-child(30) {
  transform: translateX(50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}
* > .-enter-x:nth-child(30) {
  transform: translateX(-50px);
  opacity: 0;
  animation: enter-x-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}
* > .enter-y:nth-child(30) {
  transform: translateY(50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}
* > .-enter-y:nth-child(30) {
  transform: translateY(-50px);
  opacity: 0;
  animation: enter-y-animation 0.4s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}
